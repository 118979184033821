import React, {useEffect, useState} from "react";
import {Row, Col} from "react-bootstrap";
import Decimal from "decimal.js";
import copyImg from '../images/copy-rounded.svg';
import nft1 from '../images/nft1.svg';
import nft2 from '../images/nft2.svg';
import fire from '../images/firered.svg';
import separator from '../images/separator.svg';
import shadow from '../images/red-shadow.svg';
import elmocoin from '../images/elmocoin1.svg';
import nft_stake from '../images/NFT-stake.svg';
import uniswap_logo from '../images/uniswap-logo.svg';
import mexc_logo from '../images/mexc-logo.svg';
import lbank_logo from '../images/lbank-logo.svg';
import bitmart_logo from '../images/bitmart-logo.svg';
import bitforex_logo from '../images/bitforex-logo.svg';
import coinw_logo from '../images/coinw-logo.svg';
import arrow_down from '../images/arrow-down.svg';
import checkmark_circle from '../images/checkmark-circle.svg';
import checkmark_empty from '../images/checkmark-empty.svg';
import elmo1 from '../images/elmo1.svg';
import elmo2 from '../images/elmo2.svg';
import elmo3 from '../images/elmo3.svg';
import elmo4 from '../images/elmo4.svg';
import merch from '../images/merch.png';
import card_image2 from '../images/card-image2.svg';
import card_image3 from '../images/card-image.svg';
import card_image1 from '../images/card-image1-1.svg';
import card_image0 from '../images/card-image0.svg';
import down_arrow from '../images/down-arrow.svg';
import flooz from '../images/flooz.svg';
import blocksafu from '../images/blocksafu.svg';
import coingeko from '../images/coingeko.svg';
import cmc from '../images/coinmarketcap.svg';
import ivendpay from '../images/ivendpay.svg';
import cmcof from '../images/cmcof.svg';
import twitter from '../images/twitter.svg';
import telegram from '../images/telegram.svg';
import tiktok from '../images/tiktok.svg';
import { color } from "chart.js/helpers";

const contractAddress = process.env.REACT_APP_ELMO_ADDRESS;
const ethApiKey = process.env.REACT_APP_ETHERSCAN_API_KEY;
const Main = () => {

    const [copySuccess, setCopySuccess] = useState('');
    const [totalSupply, setTotalSupply] = useState(null);
    const [burnedLast24Hours, setBurnedLast24Hours] = useState(null);
    const [burnedLast7Days, setBurnedLast7Days] = useState(null);
    const [totalHolders, setTotalHolders] = useState(null);

    const fetchTotalSupply = () => {
        // Base URL
        const baseURL = "https://api.etherscan.io/api";

        // Query parameters
        const queryParams = {
            module: 'stats',
            action: 'tokensupply',
            contractaddress: contractAddress,
            apikey: ethApiKey
        };

        const url = new URL(baseURL);
        url.search = new URLSearchParams(queryParams).toString();

        fetch(url)
            .then(response => response.json())
            .then(data => {
                let totalSupply = data.result;
                setTotalSupply(totalSupply);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const fetchBurnedTokens = async (page = 1) => {
        try {
            const queryParams = {
                module: 'account',
                action: 'tokentx',
                contractaddress: contractAddress,
                address: '0x0000000000000000000000000000000000000000',
                offset: '10000',
                page,
                sort: 'desc',
                apikey: ethApiKey
            };

            const url = new URL('https://api.etherscan.io/api');
            url.search = new URLSearchParams(queryParams).toString();

            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (!data.result) {
                return new Decimal(0);
            }

            // Set the range for the last 24 hours
            const now = new Date();
            //now.setHours(0, 0, 0, 0); // Set to 00:00 local time
            //now.setUTCHours(0, 0, 0, 0); // Set to 00:00 UTC
            //const oneDayAgo = now.getTime() / 1000; // Convert to Unix timestamp
            const currentTime = now.getTime() / 1000; // Convert to Unix timestamp

            let totalBurnedLast24Hours = new Decimal(0);

            for (const tx of data.result) {
                const value = new Decimal(tx.value).dividedBy(new Decimal(10).pow(18));
                const txDate = parseInt(tx.timeStamp, 10);

                // Only sum up tokens burned in the last 24 hours
                /*if (txDate >= oneDayAgo) {
                  totalBurnedLast24Hours = totalBurnedLast24Hours.plus(value);
                }*/
                if (txDate >= currentTime - 24 * 60 * 60) {
                    totalBurnedLast24Hours = totalBurnedLast24Hours.plus(value);
                }
            }

            // If the API returned the maximum number of results, there might be more pages.
            if (data.result.length === parseInt(queryParams.offset)) {
                const moreBurnedTokens = await fetchBurnedTokens(page + 1);
                totalBurnedLast24Hours = totalBurnedLast24Hours.plus(moreBurnedTokens);
            }

            return totalBurnedLast24Hours;
        } catch (error) {
            console.error('Failed to fetch burned tokens:', error);
            return new Decimal(0); // Return 0 if something went wrong
        }
    };

    const fetchBurned7daysTokens = async (page = 1) => {
        try {
            const queryParams = {
                module: 'account',
                action: 'tokentx',
                contractaddress: contractAddress,
                address: '0x0000000000000000000000000000000000000000',
                offset: '10000',
                page,
                sort: 'desc',
                apikey: ethApiKey
            };

            const url = new URL('https://api.etherscan.io/api');
            url.search = new URLSearchParams(queryParams).toString();

            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (!data.result) {
                return new Decimal(0);
            }

            // Set the range for the last 7 days UTC
            const now = new Date();
            //now.setUTCHours(0, 0, 0, 0); // Set to 00:00 UTC
            //now.setHours(0, 0, 0, 0); // Set to 00:00 local time
            //now.setDate(now.getDate() - 7); // Subtract 7 days
            //const sevenDaysAgo = now.getTime() / 1000; // Convert to Unix timestamp

            const sevenDaysAgo = now.getTime() / 1000 - 7 * 24 * 60 * 60; // Convert to Unix timestamp and subtract 7 days

            let totalBurnedLast7Days = new Decimal(0);

            for (const tx of data.result) {
                const value = new Decimal(tx.value).dividedBy(new Decimal(10).pow(18));
                const txDate = parseInt(tx.timeStamp, 10);

                // Only sum up tokens burned in the last 7 days
                if (txDate >= sevenDaysAgo) {
                    totalBurnedLast7Days = totalBurnedLast7Days.plus(value);
                }
            }

            // If the API returned the maximum number of results, there might be more pages.
            if (data.result.length === parseInt(queryParams.offset)) {
                const moreBurnedTokens = await fetchBurnedTokens(page + 1);
                totalBurnedLast7Days = totalBurnedLast7Days.plus(moreBurnedTokens);
            }

            return totalBurnedLast7Days;
        } catch (error) {
            console.error('Failed to fetch burned tokens:', error);
            return new Decimal(0); // Return 0 if something went wrong
        }
    };

    const fetchTotalHolders = async () => {
        const url = 'https://api.covalenthq.com/v1/eth-mainnet/tokens/' + contractAddress + '/token_holders_v2/';
        const params = {'key': 'cqt_rQ6CJDD9JbwxcWHkJfw7TCcdJq8c', 'page-number': 0};

        const response = await fetch(`${url}?${new URLSearchParams(params)}`);
        const result = await response.json();

        setTotalHolders(result.data.pagination.total_count);
    };

    useEffect(() => {
        fetchTotalSupply();
        fetchBurnedTokens().then(totalBurned => {
            setBurnedLast24Hours(totalBurned.toString());
        });
        fetchBurned7daysTokens().then(total7DaysBurned => {
            setBurnedLast7Days(total7DaysBurned);
        });
        fetchTotalHolders().catch(error => console.error(error));
    }, []);

    const convert = (number) => {
        return Math.ceil(Math.round((number / (10 * 10 ** (18 - 3)))) / 100)
    }

    const convert_sec = (number) => {
        if (number !== null) {
            let toNumber = Math.round(Number(new Decimal(number)));
            return toNumber.toLocaleString();
        } else {
            return "Loading...";
        }
    }

    const Ticker = () => {
        const [repeatCount, setRepeatCount] = useState(0);
        const text = 'BURNS FASTER THAN GASOLINE';

        useEffect(() => {
            const handleResize = () => {
                const textWidth = getTextWidth(text + '🔥');
                const screenWidth = window.innerWidth;
                setRepeatCount(Math.ceil(screenWidth / textWidth));
            };

            handleResize();
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        const getTextWidth = (text) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = '21px HvDTrial Match'; // Adjust this based on your font and size
            return context.measureText(text).width;
        };

        return (
            <div className="ticker">
                {Array(repeatCount).fill(null).map((_, index) => (
                    <span key={index} className="ticker-text">
                        <img src={fire} alt='fire icon' className="fire-image"/>
                        {text}
                    </span>
                ))}
            </div>
        );
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            function () {
                setCopySuccess('Copied!');
                setTimeout(() => setCopySuccess(''), 1500); // reset the message after 1.5s
            },
            function (err) {
                console.error('Could not copy text: ', err);
            }
        );
    };

    return (
        <Row>
            <Col className="col-12 col-lg-12 btm">
                <div className="general-info">
                    <img className='nft-left' src={nft1} alt="elmofo nft"/>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1351" height="877" viewBox="0 0 1351 877"
                         fill="none">
                        <g filter="url(#filter0_f_156_23)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M400.911 172.501C405.306 123.85 429.085 79.9755 458.504 40.9788C491.506 -2.76587 528.221 -46.724 579.944 -64.8174C634.672 -83.9615 699.391 -88.8137 750.318 -61.1008C799.956 -34.0893 807.767 32.4127 841.686 77.6138C875.837 123.125 948.081 148.161 950.017 205.027C951.964 262.241 881.997 292.913 850.646 340.812C822.45 383.891 823.191 453.23 775.259 472.027C726.263 491.24 675.314 448.982 626.795 428.594C592.187 414.052 564.483 390.921 533.126 370.283C499.174 347.936 457.484 336.078 434.895 302.287C409.528 264.34 396.803 217.961 400.911 172.501Z"
                                  fill="#BA181B"/>
                        </g>
                        <defs>
                            <filter id="filter0_f_156_23" x="0.151123" y="-480.533" width="1349.91" height="1357.45"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_156_23"/>
                            </filter>
                        </defs>
                    </svg>
                    <div className="copy-contract">
                        <div style={{position: 'relative'}}>
                            <img
                                src={copyImg}
                                alt="Copy"
                                onClick={() => copyToClipboard('0x335F4e66B9B61CEE5CeaDE4e727FCEC20156B2F0')}
                            />
                            {copySuccess && (
                                <div className='clip-copy'
                                     style={{
                                         position: 'absolute',
                                         top: '-30px',
                                         left: '50%',
                                         transform: 'translateX(-50%)',
                                         backgroundColor: '#E5383B',
                                         color: 'white',
                                         padding: '5px 10px',
                                         borderRadius: '5px',
                                         boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'
                                     }}
                                >
                                    {copySuccess}
                                </div>
                            )}
                        </div>
                        <span className="address">0x335F4e66B9B61CEE5CeaDE4e727FCEC20156B2F0</span>
                    </div>
                    <div className='title'>
                        <div className='main'>
                            The fastest burning <br/> memecoin
                        </div>
                        <span className='subt'>Here to conquer the memespace!</span>
                    </div>
                    <a style={{zIndex: 9}} className='button' href="#live-data">Explore more</a>
                    <img className='nft-right' src={nft2} alt="elmofo nft"/>
                </div>
                <div className='separator'>
                    <div className='first'>
                        <Ticker/>
                    </div>
                    <div className='second'>
                        <Ticker/>
                    </div>
                </div>
                <img id='live-data' src={separator} alt="separator" className="separator_s"/>
                <div className="live_data">
                    <div className="about">
                        <h1>About the project</h1>
                        <p>It is a deflationary memecoin that delivers power with utility, transparency, and use-case.
                            We charge sellers with tax that goes directly to burn. Through a quickly decreasing supply,
                            utility, and accessibility we will conquer the memespace!</p>
                        <p className="highlight-text">- THE BURN WILL NEVER STOP</p>
                    </div>
                    <div className="card">
                        <div className="row">
                            <div className="data">
                                <p className="card-number">{convert_sec(burnedLast7Days)}</p>
                                <p className="card-text">Burned 7D</p>
                            </div>
                            <div className="data">
                                <p className="card-number"> {Number(convert(totalSupply)).toLocaleString()}</p>
                                <p className="card-text">Total Supply</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="data">
                                <p className="card-number">{convert_sec(burnedLast24Hours)}</p>
                                <p className="card-text">Burned 24h</p>
                            </div>
                            <div className="data">
                                <p className="card-number">{totalHolders !== null ? totalHolders.toLocaleString() : "Loading..."}</p>
                                <p className="card-text">Holders</p>
                            </div>
                        </div>
                    </div>
                    <img className='shadow-red' src={shadow} alt="elmofo shadow"/>
                </div>
                <div className='section-title'>
                    <div className='sub-title'>Stake $ELMO</div>
                    <div className='title'>Staking</div>
                </div>
                <div className="staking-section">
                    <div className="card">
                        <div className="main-info">
                            <h2>$ELMO Steak V2</h2>
                            <p>Stake your $ELMO to earn and burn!</p>
                            <a href='https://stakingv2.elmoerc.io/' className="red-button">Stake Now</a>
                            <div className="arrow-container f">
                                <svg xmlns="http://www.w3.org/2000/svg" width="168" height="168" viewBox="0 0 168 168"
                                     fill="none">
                                    <path
                                        d="M26.9949 73.3296C24.498 69.8967 22.0763 66.3358 19.4 63.0403C18.772 62.267 17.3626 60.0143 16.2264 59.9776C14.7874 59.9314 14.3014 61.93 14.0857 62.9916C13.4413 66.1655 13.6554 69.788 13.6252 73.03C13.619 73.6797 13.3047 75.7919 13.7752 76.3423M16.2284 59.9766C18.1526 67.5578 22.1417 74.8056 25.7847 81.6822C33.1789 95.6401 47.9215 132.589 65 136.887C75.1724 139.447 87.0489 132.745 96.0753 129.047C117.123 120.425 140.01 114.006 160.258 103.653"
                                        stroke="#E5383B" stroke-width="2.79409" stroke-miterlimit="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="7 7"/>
                                </svg>
                            </div>
                        </div>
                        <img src={elmocoin} alt="Main Image" className="main-image"/>
                    </div>
                    <div className="card">
                        <img src={nft_stake} alt="Main Image" className="main-image-s"/>
                        <div className="main-info">
                            <h2>NFT Staking</h2>
                            <p>Stake your Elmofo NFT to earn and burn $ELMO</p>
                            <a href='https://nft-staking.elmoerc.io/' className="red-button">Stake Now</a>
                            <div className="arrow-container s">
                                <svg xmlns="http://www.w3.org/2000/svg" width="168" height="168" viewBox="0 0 168 168"
                                     fill="none">
                                    <path
                                        d="M140.727 73.3296C143.224 69.8967 145.646 66.3358 148.322 63.0403C148.95 62.267 150.359 60.0143 151.495 59.9776C152.934 59.9314 153.42 61.93 153.636 62.9916C154.281 66.1655 154.066 69.788 154.097 73.03C154.103 73.6797 154.417 75.7919 153.947 76.3423M151.493 59.9766C149.569 67.5578 145.58 74.8056 141.937 81.6822C134.543 95.6401 119.8 132.589 102.722 136.887C92.5494 139.447 80.6729 132.745 71.6465 129.047C50.5992 120.425 27.7117 114.006 7.46383 103.653"
                                        stroke="#E5383B" stroke-width="2.79409" stroke-miterlimit="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="7 7"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="nfts" className="nft-collection">
                    <div className="title">ELMOFOS NFT Collection</div>
                    <div className="description">
                        The Elmofos collection is the ElmoERC official collection of 1,000 2D NFTs. They can be minted
                        on our own minting platform.
                        <br/><br/>
                        Elmofos NFTs are the veins of our project as they will allow us to invest the raised funds back
                        into the project. All NFT holders will have long-term benefit from our Elmofos; NFT Staking,
                        Staking boosts, 0% sell on Burn Portal and much more!
                    </div>
                    <div className='action-buttons'>
                        <a target='_blank' href='https://opensea.io/collection/elmofos-5' className="red-button">View
                            Collection</a>
                        <a href='https://nfts.elmoerc.io/' className="no-bg-button">Mint</a>
                    </div>
                </div>
                <div className='section-title pt-none'>
                    <div className='sub-title'>Trade $ELMO</div>
                    <div className='title'>Exchanges</div>
                </div>
                <div className="exchanges">
                    <div className="card">
                        <svg className="exchange-bg" xmlns="http://www.w3.org/2000/svg" width="275" height="164"
                             viewBox="0 0 275 164" fill="none">
                            <mask id="path-1-inside-1_156_142" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"
                                  fill="#22090A"/>
                            <path
                                d="M8.77576 1.09697H265.467V-1.09697H8.77576V1.09697ZM1.09697 154.673V8.77577H-1.09697V154.673H1.09697ZM229.267 162.352H8.77576V164.545H229.267V162.352ZM239.139 154.673V131.637H236.946V154.673H239.139ZM246.818 123.958H265.467V121.764H246.818V123.958ZM273.145 8.77576V114.085H275.339V8.77576H273.145ZM265.467 123.958C270.919 123.958 275.339 119.538 275.339 114.085H273.145C273.145 118.326 269.708 121.764 265.467 121.764V123.958ZM239.139 131.637C239.139 127.396 242.577 123.958 246.818 123.958V121.764C241.366 121.764 236.946 126.184 236.946 131.637H239.139ZM229.267 164.545C234.719 164.545 239.139 160.125 239.139 154.673H236.946C236.946 158.914 233.508 162.352 229.267 162.352V164.545ZM-1.09697 154.673C-1.09697 160.125 3.32321 164.545 8.77576 164.545V162.352C4.53488 162.352 1.09697 158.914 1.09697 154.673H-1.09697ZM265.467 1.09697C269.708 1.09697 273.145 4.53488 273.145 8.77576H275.339C275.339 3.3232 270.919 -1.09697 265.467 -1.09697V1.09697ZM8.77576 -1.09697C3.3232 -1.09697 -1.09697 3.32321 -1.09697 8.77577H1.09697C1.09697 4.53489 4.53488 1.09697 8.77576 1.09697V-1.09697Z"
                                fill="#BA181B" mask="url(#path-1-inside-1_156_142)"/>
                        </svg>
                        <img src={uniswap_logo} alt="Uniswap Logo" className="logo"/>
                        <span>UNISWAP</span>
                        <a href="https://app.uniswap.org/#/swap" className="link-square">
                            <span className="arrow">&uarr;</span>
                        </a>
                    </div>
                    <div className="card">
                        <svg className="exchange-bg" xmlns="http://www.w3.org/2000/svg" width="275" height="164"
                             viewBox="0 0 275 164" fill="none">
                            <mask id="path-1-inside-1_156_142" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"
                                  fill="#22090A"/>
                            <path
                                d="M8.77576 1.09697H265.467V-1.09697H8.77576V1.09697ZM1.09697 154.673V8.77577H-1.09697V154.673H1.09697ZM229.267 162.352H8.77576V164.545H229.267V162.352ZM239.139 154.673V131.637H236.946V154.673H239.139ZM246.818 123.958H265.467V121.764H246.818V123.958ZM273.145 8.77576V114.085H275.339V8.77576H273.145ZM265.467 123.958C270.919 123.958 275.339 119.538 275.339 114.085H273.145C273.145 118.326 269.708 121.764 265.467 121.764V123.958ZM239.139 131.637C239.139 127.396 242.577 123.958 246.818 123.958V121.764C241.366 121.764 236.946 126.184 236.946 131.637H239.139ZM229.267 164.545C234.719 164.545 239.139 160.125 239.139 154.673H236.946C236.946 158.914 233.508 162.352 229.267 162.352V164.545ZM-1.09697 154.673C-1.09697 160.125 3.32321 164.545 8.77576 164.545V162.352C4.53488 162.352 1.09697 158.914 1.09697 154.673H-1.09697ZM265.467 1.09697C269.708 1.09697 273.145 4.53488 273.145 8.77576H275.339C275.339 3.3232 270.919 -1.09697 265.467 -1.09697V1.09697ZM8.77576 -1.09697C3.3232 -1.09697 -1.09697 3.32321 -1.09697 8.77577H1.09697C1.09697 4.53489 4.53488 1.09697 8.77576 1.09697V-1.09697Z"
                                fill="#BA181B" mask="url(#path-1-inside-1_156_142)"/>
                        </svg>
                        <img src={mexc_logo} alt="Mexc Logo" className="logo"/>
                        <span>MEXC</span>
                        <a href="https://www.mexc.com/exchange/ELMO_USDT" className="link-square">
                            <span className="arrow">&uarr;</span>
                        </a>
                    </div>
                    <div className="card">
                        <svg className="exchange-bg" xmlns="http://www.w3.org/2000/svg" width="275" height="164"
                             viewBox="0 0 275 164" fill="none">
                            <mask id="path-1-inside-1_156_142" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"
                                  fill="#22090A"/>
                            <path
                                d="M8.77576 1.09697H265.467V-1.09697H8.77576V1.09697ZM1.09697 154.673V8.77577H-1.09697V154.673H1.09697ZM229.267 162.352H8.77576V164.545H229.267V162.352ZM239.139 154.673V131.637H236.946V154.673H239.139ZM246.818 123.958H265.467V121.764H246.818V123.958ZM273.145 8.77576V114.085H275.339V8.77576H273.145ZM265.467 123.958C270.919 123.958 275.339 119.538 275.339 114.085H273.145C273.145 118.326 269.708 121.764 265.467 121.764V123.958ZM239.139 131.637C239.139 127.396 242.577 123.958 246.818 123.958V121.764C241.366 121.764 236.946 126.184 236.946 131.637H239.139ZM229.267 164.545C234.719 164.545 239.139 160.125 239.139 154.673H236.946C236.946 158.914 233.508 162.352 229.267 162.352V164.545ZM-1.09697 154.673C-1.09697 160.125 3.32321 164.545 8.77576 164.545V162.352C4.53488 162.352 1.09697 158.914 1.09697 154.673H-1.09697ZM265.467 1.09697C269.708 1.09697 273.145 4.53488 273.145 8.77576H275.339C275.339 3.3232 270.919 -1.09697 265.467 -1.09697V1.09697ZM8.77576 -1.09697C3.3232 -1.09697 -1.09697 3.32321 -1.09697 8.77577H1.09697C1.09697 4.53489 4.53488 1.09697 8.77576 1.09697V-1.09697Z"
                                fill="#BA181B" mask="url(#path-1-inside-1_156_142)"/>
                        </svg>
                        <img src={lbank_logo} alt="Lbank Logo" className="logo"/>
                        <span>LBANK</span>
                        <a href="https://www.lbank.com/trade/elmo_usdt/" className="link-square">
                            <span className="arrow">&uarr;</span>
                        </a>
                    </div>
                    <div className="card">
                        <svg className="exchange-bg" xmlns="http://www.w3.org/2000/svg" width="275" height="164"
                             viewBox="0 0 275 164" fill="none">
                            <mask id="path-1-inside-1_156_142" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"
                                  fill="#22090A"/>
                            <path
                                d="M8.77576 1.09697H265.467V-1.09697H8.77576V1.09697ZM1.09697 154.673V8.77577H-1.09697V154.673H1.09697ZM229.267 162.352H8.77576V164.545H229.267V162.352ZM239.139 154.673V131.637H236.946V154.673H239.139ZM246.818 123.958H265.467V121.764H246.818V123.958ZM273.145 8.77576V114.085H275.339V8.77576H273.145ZM265.467 123.958C270.919 123.958 275.339 119.538 275.339 114.085H273.145C273.145 118.326 269.708 121.764 265.467 121.764V123.958ZM239.139 131.637C239.139 127.396 242.577 123.958 246.818 123.958V121.764C241.366 121.764 236.946 126.184 236.946 131.637H239.139ZM229.267 164.545C234.719 164.545 239.139 160.125 239.139 154.673H236.946C236.946 158.914 233.508 162.352 229.267 162.352V164.545ZM-1.09697 154.673C-1.09697 160.125 3.32321 164.545 8.77576 164.545V162.352C4.53488 162.352 1.09697 158.914 1.09697 154.673H-1.09697ZM265.467 1.09697C269.708 1.09697 273.145 4.53488 273.145 8.77576H275.339C275.339 3.3232 270.919 -1.09697 265.467 -1.09697V1.09697ZM8.77576 -1.09697C3.3232 -1.09697 -1.09697 3.32321 -1.09697 8.77577H1.09697C1.09697 4.53489 4.53488 1.09697 8.77576 1.09697V-1.09697Z"
                                fill="#BA181B" mask="url(#path-1-inside-1_156_142)"/>
                        </svg>
                        <img src={bitmart_logo} alt="Bitmart Logo" className="logo"/>
                        <span>BITMART</span>
                        <a href="https://www.bitmart.com/trade/en-US?layout=basic&theme=dark&symbol=ELMO_USDT"
                           className="link-square">
                            <span className="arrow">&uarr;</span>
                        </a>
                    </div>
                    <div className="card">
                        <svg className="exchange-bg" xmlns="http://www.w3.org/2000/svg" width="275" height="164"
                             viewBox="0 0 275 164" fill="none">
                            <mask id="path-1-inside-1_156_142" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"
                                  fill="#22090A"/>
                            <path
                                d="M8.77576 1.09697H265.467V-1.09697H8.77576V1.09697ZM1.09697 154.673V8.77577H-1.09697V154.673H1.09697ZM229.267 162.352H8.77576V164.545H229.267V162.352ZM239.139 154.673V131.637H236.946V154.673H239.139ZM246.818 123.958H265.467V121.764H246.818V123.958ZM273.145 8.77576V114.085H275.339V8.77576H273.145ZM265.467 123.958C270.919 123.958 275.339 119.538 275.339 114.085H273.145C273.145 118.326 269.708 121.764 265.467 121.764V123.958ZM239.139 131.637C239.139 127.396 242.577 123.958 246.818 123.958V121.764C241.366 121.764 236.946 126.184 236.946 131.637H239.139ZM229.267 164.545C234.719 164.545 239.139 160.125 239.139 154.673H236.946C236.946 158.914 233.508 162.352 229.267 162.352V164.545ZM-1.09697 154.673C-1.09697 160.125 3.32321 164.545 8.77576 164.545V162.352C4.53488 162.352 1.09697 158.914 1.09697 154.673H-1.09697ZM265.467 1.09697C269.708 1.09697 273.145 4.53488 273.145 8.77576H275.339C275.339 3.3232 270.919 -1.09697 265.467 -1.09697V1.09697ZM8.77576 -1.09697C3.3232 -1.09697 -1.09697 3.32321 -1.09697 8.77577H1.09697C1.09697 4.53489 4.53488 1.09697 8.77576 1.09697V-1.09697Z"
                                fill="#BA181B" mask="url(#path-1-inside-1_156_142)"/>
                        </svg>
                        <img src={bitforex_logo} alt="Bitforex Logo" className="logo"/>
                        <span>BITFOREX</span>
                        <a href="https://www.bitforex.com/en/spot/elmo_usdt" className="link-square">
                            <span className="arrow">&uarr;</span>
                        </a>
                    </div>
                    <div className="card">
                        <svg className="exchange-bg" xmlns="http://www.w3.org/2000/svg" width="275" height="164"
                             viewBox="0 0 275 164" fill="none">
                            <mask id="path-1-inside-1_156_142" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"/>
                            </mask>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M274.242 8.77576C274.242 3.92904 270.313 0 265.467 0H8.77576C3.92904 0 0 3.92905 0 8.77577V154.673C0 159.519 3.92904 163.448 8.77576 163.448H229.267C234.113 163.448 238.042 159.519 238.042 154.673V131.637C238.042 126.79 241.972 122.861 246.818 122.861H265.467C270.313 122.861 274.242 118.932 274.242 114.085V8.77576Z"
                                  fill="#22090A"/>
                            <path
                                d="M8.77576 1.09697H265.467V-1.09697H8.77576V1.09697ZM1.09697 154.673V8.77577H-1.09697V154.673H1.09697ZM229.267 162.352H8.77576V164.545H229.267V162.352ZM239.139 154.673V131.637H236.946V154.673H239.139ZM246.818 123.958H265.467V121.764H246.818V123.958ZM273.145 8.77576V114.085H275.339V8.77576H273.145ZM265.467 123.958C270.919 123.958 275.339 119.538 275.339 114.085H273.145C273.145 118.326 269.708 121.764 265.467 121.764V123.958ZM239.139 131.637C239.139 127.396 242.577 123.958 246.818 123.958V121.764C241.366 121.764 236.946 126.184 236.946 131.637H239.139ZM229.267 164.545C234.719 164.545 239.139 160.125 239.139 154.673H236.946C236.946 158.914 233.508 162.352 229.267 162.352V164.545ZM-1.09697 154.673C-1.09697 160.125 3.32321 164.545 8.77576 164.545V162.352C4.53488 162.352 1.09697 158.914 1.09697 154.673H-1.09697ZM265.467 1.09697C269.708 1.09697 273.145 4.53488 273.145 8.77576H275.339C275.339 3.3232 270.919 -1.09697 265.467 -1.09697V1.09697ZM8.77576 -1.09697C3.3232 -1.09697 -1.09697 3.32321 -1.09697 8.77577H1.09697C1.09697 4.53489 4.53488 1.09697 8.77576 1.09697V-1.09697Z"
                                fill="#BA181B" mask="url(#path-1-inside-1_156_142)"/>
                        </svg>
                        <img src={coinw_logo} alt="CoinW Logo" className="logo"/>
                        <span>COINW</span>
                        <a href="https://www.coinw.com/frontSpot/spottrade?symbol=1383" className="link-square">
                            <span className="arrow">&uarr;</span>
                        </a>
                    </div>
                </div>
                <div id="roadmap" className='section-title'>
                    <img className='title-icon' src={arrow_down} alt="down arrow"/>
                    <div className='sub-title'>Roadmap</div>
                    <div className='title'>Evolution of $ELMO</div>
                </div>
                <div className="timeline">
                    <div className="phase">
                        <div className="milestone-character">
                            <div className="milestones">
                                <h2>Phase 1</h2>
                                <ul>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Launch
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        CMC/CG listing
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        1,000+ holders
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Influencer partnerships
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Trending Everywhere
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Burn over 60%
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Kill one zero
                                    </li>
                                </ul>
                            </div>
                            <img src={elmo1} alt="Elmo Character 1"/>
                        </div>
                        <div className="step">
                            <div className='first-inner'>
                                <div className="second-inner">
                                    01
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="phase second">
                        <div className="milestone-character">
                            <div className="milestones">
                                <h2>Phase 2</h2>
                                <ul>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        5,000+ holders
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Multiple CEX listings
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Become the most bullish meme
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Kill 2nd zero
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Burn over 65%
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        NFT Staking
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Start development of UE game
                                    </li>
                                </ul>
                            </div>
                            <img src={elmo2} alt="Elmo Character 2"/>
                        </div>
                        <div className="step">
                            <div className='first-inner'>
                                <div className="second-inner">
                                    02
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1384" height="1268" viewBox="0 0 1384 1268"
                         fill="none">
                        <g filter="url(#filter0_f_159_448)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M450.935 588.782C455.33 540.131 490.015 560.751 519.435 521.754C483.435 464.754 545.338 479.462 597.061 461.368C651.788 442.224 639.803 471.542 690.73 499.254C740.368 526.266 871.702 416.167 905.62 461.368C939.771 506.88 1061.43 569.754 1013.95 588.782C1015.9 645.995 841.786 554.856 810.435 602.755C782.239 645.833 837.676 735.728 782.935 769.255C733.939 788.468 739.249 832.736 690.73 812.349C656.122 797.807 628.417 774.676 597.061 754.037C563.109 731.69 521.419 719.833 498.83 686.042C473.462 648.094 446.827 634.242 450.935 588.782Z"
                                  fill="#BA181B"/>
                        </g>
                        <defs>
                            <filter id="filter0_f_159_448" x="0.512939" y="0.338867" width="1474.29" height="1267.26"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_159_448"/>
                            </filter>
                        </defs>
                    </svg>
                    <div className="phase three">
                        <div className="milestone-character">
                            <div className="milestones">
                                <h2>Phase 3</h2>
                                <ul>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        10,000+ holders
                                    </li>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        Tier 1 CEX listing
                                    </li>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        Elmo charity
                                    </li>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        Kill 3rd zero
                                    </li>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        Burn over 70%
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        ELMO merch
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        UE shooter <a target="_blank" rel="noreferrer" style={{color: "white"}} href="https://www.meme-alliance.com">Meme Alliance</a>
                                    </li>
                                </ul>
                            </div>
                            <img src={elmo3} alt="Elmo Character 3"/>
                        </div>
                        <div className="step">
                            <div className='first-inner'>
                                <div className="second-inner">
                                    03
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="phase four">
                        <div className="milestone-character">
                            <div className="milestones">
                                <h2>Phase 4</h2>
                                <ul>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        20,000+ holders
                                    </li>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        Multiple Tier 1 CEX listings
                                    </li>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        Unreal Engine game release
                                    </li>
                                    <li>
                                        <img className='checkmark-circle' src={checkmark_circle} alt="Checkmark"/>
                                        Payment usability
                                    </li>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        Burned over 75%
                                    </li>
                                    <li>
                                        <img className='checkmark-circle empty' src={checkmark_empty} alt="Checkmark"/>
                                        ElmoERC price over 1 USD
                                    </li>
                                </ul>
                            </div>
                            <img src={elmo4} alt="Elmo Character 4"/>
                        </div>
                        <div className="step">
                            <div className='first-inner'>
                                <div className="second-inner">
                                    04
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="shop" className='section-title'>
                    <div className='sub-title'>Wearables and Collectibles</div>
                    <div className='title'>Merch</div>
                </div>
                <div className='merch'>
                    <img className='merch' src={merch} alt="Elmo merch"/>
                    <a target="_blank" href="https://shop.elmoerc.io/#!/" className="red-button">Buy ElmoERC merch</a>
                </div>
                <div id='tokenomics' className='section-title'>
                    <div className='sub-title'>$ELMO Blockchain Info</div>
                    <div className='title'>Tokenomics</div>
                </div>
                <div className="tokenomics">
                    <div className="supply">
                        <p className="card-text">Max Supply</p>
                        <p className="card-number">1,000,000,000 $ELMO</p>
                        <br/>
                        <p className="card-text">Total Supply</p>
                        <p className="card-number">{Number(convert(totalSupply)).toLocaleString()} $ELMO</p>
                        <img className='tk-1' src={card_image1} alt="Elmo supply"/>
                    </div>
                    <div className="tax">
                        <p className="card-text">Sell Tax</p>
                        <p className="card-number">10%</p>
                        <p className="zero-fee">0% Fee on Buy</p>
                        <img className='tk-2' src={card_image2} alt="Elmo tax"/>
                    </div>
                    <div className="burned">
                        <p className="card-text">Total $ELMO burned</p>
                        <p className="card-number">
                            {((1 - (Number(convert(totalSupply)) / 1000000000)) * 100).toFixed(2)}%
                        </p>
                        <img className='tk-3' src={card_image3} alt="Elmo burned"/>
                    </div>
                    <div className="details full-width-row">
                        <p className="card-text">
                            <span>The burn never stops</span>
                            We burn through 10% sell tax on DEX which directly goes to 0x0...0000, actively decreasing
                            the supply.
                            All our utility and use cases also burns $ELMO assuring a never ending burn mechanism.
                            Through burns we create scarcity, increase liquidity strength and increase your position
                            while holding.
                        </p>
                        <img className='tk-last' src={card_image0} alt="Elmo details"/>
                    </div>
                </div>
                <div id="our-partners" className='section-title'>
                    <img className='title-icon' src={down_arrow} alt="down arrow"/>
                    <div className='title'>Our partners</div>
                </div>
                <div className="partners">
                    <img src={flooz} alt="Flooz"/>
                    <img src={blocksafu} alt="BlockSafu"/>
                    <img src={coingeko} alt="CoinGeko"/>
                    <img src={cmc} alt="CoinMarketCap"/>
                    <img src={ivendpay} alt="iVendPay"/>
                    <img src={cmcof} alt="CoinMarketCapOf"/>
                </div>
                <div className="footer">
                    <div className='footer-title'>
                        Stay <div className='text-container'>connected <div className="gradient-line"></div></div><br/>
                        with us
                    </div>
                    <div className='socials'>
                        <a href='https://www.twitter.com/realelmoerc' target='_blank' rel="noreferrer"
                           className='twitter'>
                            <img src={twitter} alt='elmo twitter'/>
                        </a>
                        <a href='https://t.me/RealElmoERC' target='_blank' rel="noreferrer" className='telegram'>
                            <img src={telegram} alt='elmo telegram'/>
                        </a>
                        <a href='https://www.tiktok.com/@elmoerc' target='_blank' rel="noreferrer" className='tiktok'>
                            <img src={tiktok} alt='elmo tiktok'/>
                        </a>
                    </div>
                    <div className='links'>
                        <a href='https://blocksafu.com/audit/0xde9EA38eF2ecA5438b6F46A563Cb4fcA23A03885' target='_blank'
                           rel="noreferrer" className='twitter'>
                            <span>Staking Audit Report</span>
                        </a>
                        <a href='https://blocksafu.com/audit/0x335F4e66B9B61CEE5CeaDE4e727FCEC20156B2F0'
                           target='_blank' rel="noreferrer" className='tiktok'>
                            <span>ETH Token Audit Report</span>
                        </a>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="footer-text">
                    We are not affiliated with the character Elmo nor with Sesame Street. Invest at own risk.
                </div>
            </Col>
        </Row>
    );
};

export default Main;
