import "./css/App.css";
import "./css/Header.css";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Main from "./components/Main";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Header />
      <section
        className="section section--pb0 section--first"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <Container>
          <Main />
        </Container>
      </section>
    </>
  );
}

export default App;
