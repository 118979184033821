//Header.js
import React, {useState} from 'react';
import LanguageSelector from '../components/LanguageSelector';
import logo from '../images/elmologo-20.svg';
import {useTranslation} from 'react-i18next';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="header__content">
                                <button className={`header__btn ${isOpen ? 'header__btn--active' : ''}`} type="button"
                                        aria-label="header__nav" onClick={toggleMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <div className="header__logo">
                                    <img src={logo} alt="ElmoERC Logo"/>
                                    <ul className={`header__nav ${isOpen ? 'header__nav--active' : ''}`}
                                        id="header__nav">
                                        <li>
                                            <a href="https://www.elmoerc.io/">{t("Home")}</a>
                                        </li>
                                        <li>
                                            <a href="#tokenomics">{t("Tokenomics")}</a>
                                        </li>
                                        <li>
                                            <a href="#roadmap">{t("Roadmap")}</a>
                                        </li>
                                        <li>
                                            <a href="#our-partners">{t("Partners")}</a>
                                        </li>
                                        <li>
                                            <a href="#nfts">{t("NFT")}</a>
                                        </li>
                                        <li>
                                            <a href="#shop">{t("Shop")}</a>
                                        </li>
                                    </ul>
                                    <a className='button' target="_blank" rel="noreferrer"
                                       href="https://app.uniswap.org/swap?theme=dark&inputCurrency=ETH&outputCurrency=0x335F4e66B9B61CEE5CeaDE4e727FCEC20156B2F0">Buy
                                        $ELMO</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;